import * as React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

// components
import Layout from '../components/layout'

// images
import { IconArrowRight } from '../components/icons';
import { Trans } from 'react-i18next';

// seo
const seo = {
  title: 'seo.services.title',
  description: 'seo.services.description'
}

const ServicosPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark;

  return (
    <>
      <Layout seo={seo}>
        <section className="pageHeader">
          <div className="container">
            <h1><Trans i18nKey={seo.title} /></h1>
            <h2 className="title title--display">
              <Trans i18nKey="services.headline" />
            </h2>
          </div>
        </section>

        <div className="container">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-md">
            {edges.map(edge => {
              const { frontmatter } = edge.node;
              const path = frontmatter.path.split('/')[1];
              return (
                <Link key={frontmatter.path} className="card card--link" to={path}>
                  <div className="card__body">
                    <h3>
                      <Trans i18nKey={`services.${path}.title`} />
                    </h3>
                    <p>
                      <Trans i18nKey={`services.${path}.description`} />
                    </p>
                  </div>
                  <span className="card__action button button--nude button--reveal">
                    <IconArrowRight className="icon" />
                  </span>
                </Link>
              )
            })}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ServicosPage

export const query = graphql`
  query ($language: String!) {
    allMarkdownRemark(
      sort: {fields: frontmatter___title}
      filter: {frontmatter: {
        posttype: {eq: "servico"}, 
        locale: {eq: $language}
      }}
    ) {
      edges {
        node {
          id
          frontmatter {
            posttype
            path
            title
            description
          }
        }
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["servicos", "layout", "header", "footer"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`